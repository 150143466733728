<template>
    <div>
        <b-card class="rounded-lg" title="Mobile data Subscription">
            <b-card-text>
                <b-card>
                    <b-container>
                        <b-row class="">
                            <b-col
                                xl="3"
                                sm="6"
                                class="p-1"
                                v-for="data in 4"
                                :key="data"
                            >
                                <b-card
                                    class="shadow mb-0 border2 border-thirdColor rounded-lg"
                                >
                                    <b-card-text>
                                        <div
                                            class="d-flex flex-column"
                                            style="height: 320px"
                                        >
                                            <div
                                                class="d-flex smallGap align-items-center"
                                            >
                                                <img
                                                    width="50px"
                                                    src="@/assets/icon/coffeCup.png"
                                                />
                                                <h3
                                                    class="mb-0 text-uppercase font-weight-bolder"
                                                    style="letter-spacing: 2px"
                                                    v-text="'Espresso'"
                                                />
                                            </div>

                                            <div
                                                class="mt-2 d-flex justify-content-between"
                                            >
                                                <h1>10000</h1>

                                                <div class="d-flex flex-column">
                                                    <img
                                                        width="35px"
                                                        src="@/assets/icon/chaticon.png"
                                                    />
                                                    <p
                                                        class="text-lowercase font-weight-bolder"
                                                    >
                                                        sms /
                                                        {{ $t(locale.month) }}
                                                    </p>
                                                </div>
                                            </div>

                                            <div>
                                                <hr />
                                            </div>

                                            <div
                                                class="d-flex justify-content-between align-items-center"
                                            >
                                                <h1 class="text-danger mb-0">
                                                    7500:-
                                                </h1>

                                                <p
                                                    class="text-lowercase mb-0 font-weight-bolder"
                                                >
                                                    /{{ $t(locale.month) }}
                                                </p>
                                            </div>

                                            <div class="mt-auto">
                                                <b-button
                                                    v-ripple.400
                                                    block
                                                    variant="secondaryColor"
                                                >
                                                    <span v-if="loadingStatus">
                                                        <b-spinner
                                                            small
                                                            type="grow"
                                                        />
                                                        {{
                                                            $t(locale.loading)
                                                        }}...
                                                    </span>

                                                    <span
                                                        v-else
                                                        v-text="
                                                            $t(
                                                                locale.upgradeBtn
                                                            )
                                                        "
                                                    />
                                                </b-button>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-card>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
export default {
    name: 'CustomerCare',
    data() {
        return {
            loadingStatus: false,
            locale: {
                month: 'Message.month',
                loading: 'Message.loading',
                upgradeBtn: 'customerCareCard.button',
            },
        };
    },
};
</script>

<style scoped lang="scss"></style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
